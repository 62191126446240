import React from 'react';
import '../css/Modal.css';  // We will define the CSS for the modal

const Modal = ({ show, onClose, onSave, planName, setPlanName }) => {
  if (!show) return null;

  const defaultName = `Enter a name`;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Save Meal Plan</h2>
        <input
          type="text"
          value={planName}
          onChange={(e) => setPlanName(e.target.value)}
          placeholder={defaultName} 
          className="modal-input"
        />
        <div className="modal-buttons">
          <button className="cancel-btn" onClick={onClose}>Cancel</button>
          <button className="save-btn" onClick={onSave} disabled={!planName}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
