import React, { useContext } from 'react';
import { UserContext } from './context/UserContext';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './css/General.css';


const LandingPage = () => {
    const { user } = useContext(UserContext);  // Access user data from context
    const { t } = useTranslation();
    //const { t } = useTranslation(['common', 'errors']); // Load specific namespaces


    return (
        <div className="general-page">
            {/* Main content */}
            <div className="main-content">
                <h1>
                    {t('welcomeGuru')}, {user ? user.fullName : 'User'}!
                </h1>
                <p>Your one-stop shop for planning meals and managing shopping lists.</p>

                {/* Display options to browse flyers, create meal plan, etc. */}
                <div className="options-grid">
                    <div className="option-card">
                        <Link to="/browse-flyers">
                            <h2>Browse Flyers</h2>
                            <p>Check out the latest deals and offers from your favorite stores.</p>
                        </Link>
                    </div>

                    <div className="option-card">
                        <Link to="/meal-planner">
                            <h2>Meal Planner</h2>
                            <p>Plan your meals for the week and get organized.</p>
                        </Link>
                    </div>

                    <div className="option-card">
                        <Link to="/manage-shopping-lists">
                            <h2>Manage Shopping Lists</h2>
                            <p>Keep track of your shopping lists with ease.</p>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;



/***
import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const LandingPage = () => {
    return (
        <div className="landing-container">
            <Helmet>
                <title>Welcome | Guuru</title>
            </Helmet>
            <h1>Guuru Grocery Planner</h1>
            <div className="table-grid-3x">
                <Link to="/browse-flyers" className="grid-card-link">
                    <div className="grid-card">
                            <img
                                src="https://via.placeholder.com/100"
                                alt="Browse Flyers Icon"
                                className="grid-image"
                            />
                            <h2>Browse Flyers</h2>
                    </div>
                </Link>
                <Link to="/create-meal-plan" className="grid-card-link">
                    <div className="grid-card">
                            <img
                                src="https://via.placeholder.com/100"
                                alt="Create Meal Plan Icon"
                                className="grid-image"
                            />
                            <h2>Create Meal Plan</h2>
                    </div>
                </Link>
                <Link to="/manage-shopping-lists" className="grid-card-link">
                    <div className="grid-card">
                        <img
                            src="https://via.placeholder.com/100"
                            alt="Manage Shopping Lists Icon"
                            className="grid-image"
                        />
                        <h2>Manage Shopping Lists</h2>
                    </div>
                </Link>    
            </div>

            <div style={{ marginTop: '20px' }}>
                <Link to="/logout">Logout</Link>
            </div>
        </div>
    );
};

export default LandingPage;

***/