import React, { useState, useEffect, useContext } from 'react';
import { ShoppingContext } from '../context/ShoppingContext';
import '../css/Modal.css';

const AddShoppingList = ({ show, onClose, search, setSearch, searchResults }) => {
    // Get shopping context functions and state
    const { shoppingList, addItemToShoppingList, removeItemFromShoppingList, fetchShoppingList } = useContext(ShoppingContext);
    const [addedItems, setAddedItems] = useState(new Set()); // Track which items are in the shopping list

    // Update the addedItems whenever shoppingList changes
    useEffect(() => {
        if (shoppingList && Array.isArray(shoppingList)) {
            const itemsSet = new Set(
                shoppingList
                    .filter(item => item && item.itemName) // Guard clause to ensure item is not undefined/null
                    .map(item => item.itemName)
            );
            setAddedItems(itemsSet);
        }
    }, [shoppingList]);

    //Hide Modal
    if (!show) return null;

    // Local helper function to handle adding an item
    const handleAddItem = async (merchantName, item) => {
        try {
            await addItemToShoppingList(merchantName, item); // Use the function from ShoppingContext

            // Fetch the updated shopping list
            await fetchShoppingList();
        } catch (error) {
            console.error('Error adding item to shopping list:', error);
        }
    };

    // Local helper function to handle removing an item
    const handleRemoveItem = (merchantName, item) => {
        if (merchantName && item) {
            removeItemFromShoppingList(merchantName, item);

            // Update the addedItems set locally to reflect the removal immediately
            setAddedItems(prevSet => {
                const updatedSet = new Set(prevSet);
                updatedSet.delete(item.itemName);
                return updatedSet;
            });

            // Fetch the updated shopping list
            //await fetchShoppingList();
        } else {
            console.error('Invalid item or merchant name when removing item');
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-big-content" role="dialog" aria-labelledby="modalTitle" aria-modal="true">

                {/* Close button in the top right */}
                <button className="modal-close-button" onClick={onClose} aria-label="Close modal">
                    &times;
                </button>

                <h2 id="modalTitle">Add Items</h2>
                <label htmlFor="searchInput">Search for an item across all our flyers.</label>
                <input
                    type="text"
                    id="searchInput"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Name of ingredient?"
                    className="modal-input"
                />

                {/* Display search results */}
                {searchResults && searchResults.length > 0 && (
                    <ul className="modal-search-results">
                        {searchResults.map((item, i) => (
                            <li key={i} className="modal-search-result-item">
                                <img
                                    src={item.itemImageUrl
                                        ? `/api/flyers/proxy-image?url=${encodeURIComponent(item.itemImageUrl)}`
                                        : 'https://via.placeholder.com/50'}
                                    alt={item.itemName}
                                    className="item-image"
                                />
                                <span>{item.itemName} - {item.merchantName} - {item.itemPrice}</span>
                                {addedItems.has(item.itemName) ? (
                                    <button
                                        onClick={() => handleRemoveItem(item.merchantName, item)}
                                        className="icon-button"
                                    >
                                        <i className="fas fa-circle-check" style={{ color: '#28a745', fontSize: '18px' }}></i>
                                    </button>
                                ) : (
                                    <button
                                        onClick={() => handleAddItem(item.merchantName, item)}
                                        className="icon-button"
                                    >
                                        <i className="fas fa-circle-plus" style={{ color: '#007bff', fontSize: '18px' }}></i>
                                    </button>
                                )}
                            </li>
                        ))}
                    </ul>
                )}

                <div className="modal-buttons">
                    <button className="save-modal" onClick={onClose}>I'm Done</button>
                </div>
            </div>
        </div>
    );
};

export default AddShoppingList;
