import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import BrowseFlyers from './components/BrowseFlyers';
import MealPlanner from './components/MealPlanner';
import MealPreferences from './components/MealPreferences';
import FlyerItems from './components/FlyerItems';
import ProtectedRoutes from './components/ProtectedRoutes';
import ManageShoppingLists from './components/ManageShoppingLists';
import ShoppingList from './components/ShoppingList';
import ShoppingArchive from './components/ShoppingArchive';
import Login from './components/Login';
import Signup from './components/Signup';
import ConfirmEmail from './components/ConfirmEmail';
import Logout from './components/Logout';
import OAuthSuccess from './components/OAuthSuccess';
import { UserProvider } from './components/context/UserContext';
import { ShoppingProvider } from './components/context/ShoppingContext';



import './App.css'; // Import central styles

function App() {
  return (
    <UserProvider>
      <ShoppingProvider>
        <Router>
          <Routes>

            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/register" element={<Signup />} />
            <Route path="/confirm-email/:token" element={<ConfirmEmail />} />
            <Route path="/oauth-success" element={<OAuthSuccess />} />

            {/* Protect routes that require login */}
            <Route element={<ProtectedRoutes />}>
              <Route path="/home" element={<LandingPage />} />
              <Route path="/browse-flyers" element={<BrowseFlyers />} />
              <Route path="/flyers/:merchantId" element={<FlyerItems />} />
              <Route path="/meal-planner" element={<MealPlanner />} />
              <Route path="/meal-prefs" element={<MealPreferences />} />
              <Route path="/manage-shopping-lists" element={<ManageShoppingLists />} />
              <Route path="/shopping-list" element={<ShoppingList />} />
              <Route path="/shopping-archive" element={<ShoppingArchive />} />
            </Route>

          </Routes>
        </Router>
      </ShoppingProvider>
    </UserProvider>
  );
}

export default App;
