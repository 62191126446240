import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './css/HamburgerMenu.css';

const HamburgerMenu = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className="hamburger-container">
            <div className="hamburger-icon" onClick={toggleMenu}>
                <div className={menuOpen ? "bar open" : "bar"}></div>
                <div className={menuOpen ? "bar open" : "bar"}></div>
                <div className={menuOpen ? "bar open" : "bar"}></div>
            </div>

            {menuOpen && (
                <div className="menu-content">
                    <ul>
                        <li><Link onClick={toggleMenu} to="/home">Home</Link></li>
                        <li><Link onClick={toggleMenu} to="/browse-flyers">Browse Flyers</Link></li>
                        <li><Link onClick={toggleMenu} to="/meal-planner">Meal Planner</Link></li>
                        <li><Link onClick={toggleMenu} to="/shopping-lists">Shopping List</Link></li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default HamburgerMenu;
