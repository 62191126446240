import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from './context/UserContext';
import axios from 'axios';
import './css/General.css';
import './css/Meal.css';

const Preferences = () => {
  const { user, setUser } = useContext(UserContext);
  const [preferences, setPreferences] = useState(user.dietaryPreferences || {});
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Fetch initial preferences from the database when the component mounts
  useEffect(() => {
    const fetchPreferences = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found. User not authenticated.');
        return;
      }

      try {
        const res = await axios.get('/api/meal/preferences', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Log fetched preferences and check their structure
        console.log('Fetched preferences:', res.data.dietaryPreferences);

        // Assuming the backend response contains { dietaryPreferences: {...} }
        setPreferences(res.data.dietaryPreferences || {});
      } catch (error) {
        console.error('Error fetching preferences:', error);
      }
    };

    fetchPreferences();
  }, []);  // Empty dependency array ensures this only runs once when component mounts

  // Log the preferences state to debug what's being rendered
  useEffect(() => {
    console.log('Current preferences state:', preferences);
  }, [preferences]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setSuccessMessage('');
    setErrorMessage('');

    // Get the token from localStorage (or wherever it's stored)
    const token = localStorage.getItem('token');

    if (!token) {
      console.error('No token found. User not authenticated.');
      setIsLoading(false);
      return;
    }

    try {
      // Attach the token to the Authorization header
      const res = await axios.put('/api/meal/preferences',
        { dietaryPreferences: preferences },  // Send preferences in the body
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setUser({ ...user, dietaryPreferences: preferences });
      setSuccessMessage('Preferences updated successfully!');
      console.log('Response from API:', res.data);
    } catch (error) {
      console.error('Error updating preferences', error);
      setErrorMessage('Failed to update preferences. Please try again.');
    } finally {
      setIsLoading(false);  // Reset loading state
    }
  };

  // Handle dynamic checkbox changes for any preference
  const handleCheckboxChange = (preferenceName) => {
    setPreferences({
      ...preferences,
      [preferenceName]: !preferences[preferenceName],  // Toggle the specific preference
    });
  };

  return (
    <div className="general-page">
      <h2>Update Dietary Preferences</h2>
      <div className='main-container'>
        <div className='preferences-container'>
          {/* Success Message */}
          {successMessage && (<div className="success-message">{successMessage}</div>)}

          {/* Error Message */}
          {errorMessage && (<div className="error-message">{errorMessage}</div>)}

          <div class="form-container">
            <form onSubmit={handleSubmit}>

              {/* Vegetarian Checkbox */}
              <label htmlFor="vegetarian">
                <input
                  type="checkbox"
                  id="vegetarian"
                  checked={preferences.vegetarian}  // Default to false if undefined
                  onChange={() => handleCheckboxChange('vegetarian')}
                />
                Vegetarian
              </label>

              {/* Halal Checkbox */}
              <label htmlFor="halal">
                <input
                  type="checkbox"
                  id="halal"
                  checked={preferences.halal}  // Default to false if undefined
                  onChange={() => handleCheckboxChange('halal')}
                />
                Halal
              </label>

              {/* Vegan Checkbox */}
              <label htmlFor="vegan">
                <input
                  type="checkbox"
                  id="vegan"
                  checked={preferences.vegan}  // Default to false if undefined
                  onChange={() => handleCheckboxChange('vegan')}
                />
                Vegan
              </label>

              {/* Gluten-Free Checkbox */}
              <label htmlFor="glutenFree">
                <input
                  type="checkbox"
                  id="glutenFree"
                  checked={preferences.glutenFree}  // Default to false if undefined
                  onChange={() => handleCheckboxChange('glutenFree')}
                />
                Gluten-Free
              </label>

              {/* Submit Button with Loading Indicator */}
              <button type="submit" className="save-btn" disabled={isLoading}>
                {isLoading ? 'Saving...' : 'Save Preferences'}
              </button>

            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preferences;
