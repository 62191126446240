import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from './context/UserContext';  // Import UserContext
import TopBar from './TopBar';  


const ProtectedRoutes = () => {
    const { user, loading } = useContext(UserContext);  // Get user and loading state

    console.log('ProtectedRoutes - Loading state:', loading);
    console.log('ProtectedRoutes - User state:', user);

    // If still loading, display a loader or placeholder
    if (loading) {
        return <p>Loading...</p>;  // You can replace this with a proper loading spinner if needed
    }
    
    // Only check for user once loading is complete
    if (!user) {
        console.log('No user found, redirecting to /login');
        return <Navigate to="/login" />;
    }

    // If user is authenticated, render the protected content
    return (
        <>
            <TopBar />  {/* TopBar will appear on all protected routes */}
            <Outlet />  {/* Outlet renders the nested/protected components */}
        </>
    );
};

export default ProtectedRoutes;
