import React from 'react';
import HamburgerMenu from './HamburgerMenu';  // HamburgerMenu is nested in TopBar
import Avatar from './Avatar';  // Avatar is part of TopBar
import ShoppingIcon from './ShoppingIcon';  // Avatar is part of TopBar
import './css/TopBar.css';  // Import the CSS for the TopBar

const TopBar = () => {
    return (
        <header className="top-bar">
            <div class="left-section">
                <HamburgerMenu />
            </div>
            <div class="right-section">
                <ShoppingIcon />
            </div>
            <div class="right-section">
                <Avatar />
            </div>
        </header>
    );
};

export default TopBar;
