import React, { useState, useEffect, useContext } from 'react';
import { ShoppingContext } from './context/ShoppingContext';
import './css/MealPlanDisplay.css';

const MealPlanDisplay = ({ mealPlan, groceryList }) => {
    const { shoppingList, addItemToShoppingList, removeItemFromShoppingList, fetchShoppingList } = useContext(ShoppingContext);
    const [addedItems, setAddedItems] = useState(new Set()); // Track which items are in the shopping list

    // Update the addedItems whenever shoppingList changes
    useEffect(() => {
        if (shoppingList && Array.isArray(shoppingList)) {
            const itemsSet = new Set(
                shoppingList
                    .filter(item => item && item.itemName) // Guard clause to ensure item is not undefined/null
                    .map(item => item.itemName)
            );
            setAddedItems(itemsSet);
        }
    }, [shoppingList]);

    // Handle adding an item to the shopping list
    const handleAddItem = async (merchantName, item) => {
        try {
            await addItemToShoppingList(merchantName, item); // Use the function from ShoppingContext

            // Update the addedItems set locally to reflect the change immediately
            setAddedItems(prevSet => new Set(prevSet.add(item.itemName)));

            // Fetch the updated shopping list
            await fetchShoppingList();
        } catch (error) {
            console.error('Error adding item to shopping list:', error);
        }
    };

    // Handle removing an item from the shopping list
    const handleRemoveItem = async (merchantName, item) => {
        try {
            await removeItemFromShoppingList(merchantName, item); // Use the function from ShoppingContext

            // Update the addedItems set locally to reflect the removal immediately
            setAddedItems(prevSet => {
                const updatedSet = new Set(prevSet);
                updatedSet.delete(item.itemName);
                return updatedSet;
            });

            // Fetch the updated shopping list
            await fetchShoppingList();
        } catch (error) {
            console.error('Error removing item from shopping list:', error);
        }
    };

    return (
        <div>
            <br /><br />
            <h2>{mealPlan.name}</h2>
            <ul>
                {mealPlan.recipes.map((recipe, index) => (
                    <li key={index}>
                        <hr />
                        <h4>{recipe.name}</h4>
                        <strong>Ingredients:</strong>
                        <ul>
                            {recipe.ingredients.map((ingredient, i) => (
                                <li key={i}>{ingredient.itemName}</li>
                            ))}
                        </ul>
                        <strong>Instructions:</strong>
                        <ol>
                            {recipe.instructions.map((instruction, i) => (
                                <li key={i}>{instruction}</li>
                            ))}
                        </ol>
                    </li>
                ))}
            </ul>

            <br /><br /><hr />
            <h2>Grocery List</h2>
            <ul>
                {Object.entries(groceryList).map(([merchantName, items], index) => (
                    <div key={index}>
                        <h4>{merchantName}</h4>
                        <ul className='grocery'>
                            {items.map((item, i) => (
                                <li key={i}>
                                    {addedItems.has(item.itemName) ? (
                                        <button
                                            onClick={() => handleRemoveItem(merchantName, item)}
                                            className="icon-button"
                                        >
                                            <i className="fas fa-circle-check" style={{ color: '#28a745', fontSize: '18px' }}></i>
                                        </button>
                                    ) : (
                                        <button
                                            onClick={() => handleAddItem(merchantName, item)}
                                            className="icon-button"
                                        >
                                            <i className="fas fa-circle-plus" style={{ color: '#007bff', fontSize: '18px' }}></i>
                                        </button>
                                    )}
                                    <span className='grocery'>{item.itemName} - {item.itemPrice}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </ul>
        </div>
    );
};

export default MealPlanDisplay;
