import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';

export const ShoppingContext = createContext();

export const ShoppingProvider = ({ children }) => {
    const [shoppingList, setShoppingList] = useState([]);
    const [itemCount, setItemCount] = useState(0);
    const [addedItems, setAddedItems] = useState(new Set()); // Track which items are in the shopping list

    // Update the addedItems whenever shoppingList changes
    useEffect(() => {
        if (shoppingList && Array.isArray(shoppingList)) {
            const itemsSet = new Set(
                shoppingList
                    .filter(item => item && item.itemName) // Guard clause to ensure item is not undefined/null
                    .map(item => item.itemName)
            );
            setAddedItems(itemsSet);
        }
    }, [shoppingList]);

    // Memoized fetchShoppingList
    const fetchShoppingList = useCallback(async () => {
        try {
            const response = await axios.get('/api/shopping/open', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            const list = response.data.shoppingList.items;
            setShoppingList(list);
            setItemCount(list.length);
        } catch (error) {
            console.error('Error fetching shopping list:', error);
        }
    }, []);  // Empty dependency array ensures stable reference

    useEffect(() => {
        fetchShoppingList();
    }, [fetchShoppingList]);

    // Add item to shopping list
    const addItemToShoppingList = async (merchantName, item) => {
        if (!merchantName || !item || !item.itemName) {
            console.error('Invalid item or merchant name when adding item:', item.itemName, merchantName);
            return;
        }
       // alert('Adding item to shopping list:' + item.itemName);
        try {
            const response = await axios.post('/api/shopping/item/add', { ...item, merchantName }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            //alert('Adding item to shopping list:' + item.itemName); 

            if (response && response.data && response.data.shoppingList) {
                const newItem = response.data.shoppingList.items;
                const updatedList = [...shoppingList, newItem];
                setShoppingList(updatedList.filter(item => item && item.itemName)); // Filter out any undefined or incomplete items
                setItemCount(updatedList.length);
            } else {
                console.error('Invalid response when adding item to shopping list:', response);
            }
        } catch (error) {
            console.error('Error adding item to shopping list:', error);
        }
    };

    // Remove item from shopping list
    const removeItemFromShoppingList = async (merchantName, item) => {
        try {
            await axios.post('/api/shopping/item/remove', {
                itemName: item.itemName,
                merchantName
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            const updatedList = shoppingList.filter(i => i.itemName !== item.itemName);
            setShoppingList(updatedList);
            setItemCount(updatedList.length); // Update item count after removing item
        } catch (error) {
            console.error('Error removing item from shopping list:', error);
        }
    };

    // Helper function to group items by merchant
    const groupItemsByMerchant = (items) => {
        const grouped = [];
        items.forEach(item => {
            if (!item || !item.merchantName) {
                console.warn('Skipping item due to missing merchantName:', item);
                return; // Skip this item
            }

            const merchant = grouped.find(group => group.merchantName === item.merchantName);
            if (merchant) {
                merchant.items.push(item);
            } else {
                grouped.push({ merchantName: item.merchantName, items: [item] });
            }
        });
        return grouped;
    };

    // Helper function to format shopping list for email
    const formatShoppingListForEmail = (list) => {
        let htmlContent = `<h1>Your Shopping List</h1>`;
        let groupedItems = groupItemsByMerchant(list);
        let totalPrice = 0;

        groupedItems.forEach((merchantGroup) => {
            htmlContent += `<h2>Merchant: ${merchantGroup.merchantName}</h2><ul>`;
            merchantGroup.items.forEach((item) => {
                htmlContent += `<li>${item.itemName} - ${item.itemPrice}</li>`;

                // Extract the numeric value from the price string
                let numericPrice = 0;
                if (typeof item.itemPrice === 'number') {
                    numericPrice = item.itemPrice;
                } else if (typeof item.itemPrice === 'string') {
                    numericPrice = parseFloat(item.itemPrice.replace(/[^0-9.]/g, '')) || 0;
                }
                totalPrice += numericPrice;
            });
            htmlContent += `</ul>`;
        });

        htmlContent += `<hr /><h3>Total Price: $${totalPrice.toFixed(2)}</h3>`;
        return htmlContent;
    };

    return (
        <ShoppingContext.Provider value={{
            shoppingList,
            itemCount,
            addedItems,
            fetchShoppingList,
            addItemToShoppingList,
            removeItemFromShoppingList,
            groupItemsByMerchant,
            formatShoppingListForEmail
        }}>
            {children}
        </ShoppingContext.Provider>
    );
};