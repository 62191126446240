// client/src/i18n.js
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend) // loads translations from the backend
  .use(LanguageDetector) // detects user language
  .use(initReactI18next) // passes i18n instance to react-i18next
  .init({
    backend: {
        loadPath: '/locales/{{lng}}/translation.json' // Public URL path to the shared locales
      },
      fallbackLng: 'en',
      detection: {
        order: ['queryString', 'cookie', 'localStorage', 'navigator'],
        caches: ['localStorage', 'cookie']
      },
      react: {
        useSuspense: false
      }
  });

export default i18n;
