import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ShoppingContext } from './context/ShoppingContext';
import './css/FlyerItems.css';

const FlyerItems = () => {
    const { merchantId } = useParams();
    const [items, setItems] = useState([]);
    const [merchant, setMerchant] = useState({ name: '', logoUrl: '' });
    const [validity, setValidity] = useState({ validFrom: null, validTo: null });
    const [loading, setLoading] = useState(true);

    // Get shoppingList and functions from the context
    const { shoppingList, fetchShoppingList, addItemToShoppingList, removeItemFromShoppingList } = useContext(ShoppingContext);

    useEffect(() => {
        // Fetch items from API
        axios.get(`/api/flyers/items/${merchantId}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,  // Add the Bearer token
                'Content-Type': 'application/json'  // Ensure you're sending/receiving JSON
            }
        })
            .then((response) => {
                const data = response.data;

                // Ensure shoppingList exists and is an array, then enhance items with isInShoppingList
                const enhancedItems = data.map((item) => ({
                    ...item,
                    isInShoppingList: Array.isArray(shoppingList) && shoppingList.some((listItem) => listItem?._id === item?._id)
                }));

                setItems(enhancedItems || []);

                // Set validity dates from the first item
                if (enhancedItems.length > 0) {
                    setValidity({ validFrom: enhancedItems[0].validFrom, validTo: enhancedItems[0].validTo });
                } else {
                    setValidity({ validFrom: null, validTo: null });
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching items:', error);
                setLoading(false); // Stop loading on error
            });

        // Fetch merchant details separately
        axios.get(`/api/flyers/merchants/${merchantId}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,  // Add the Bearer token
                'Content-Type': 'application/json'  // Ensure you're sending/receiving JSON
            }
        })
            .then((res) => {
                const mData = res.data;

                // Set merchant name and logo URL
                setMerchant({ name: mData.merchantName, logoUrl: mData.logoUrl });
            })
            .catch((error) => console.error('Error fetching merchant details:', error));
    }, [merchantId, shoppingList]);


    //// ITEMZZ
    const renderPriceInfo = (item) => {
        let priceInfo = '';
        if (item.itemPrePriceText) priceInfo += `${item.itemPrePriceText} `;
        if (item.itemPrice !== null) priceInfo += `$${item.itemPrice}`;
        if (item.itemDiscount > 0) priceInfo += ` (${item.itemDiscount}% off)`;
        if (item.itemPostPriceText) priceInfo += ` ${item.itemPostPriceText}`;
        if (item.itemSaleStory) priceInfo += ` - ${item.itemSaleStory}`;
        return priceInfo;
    };


    // Add an item to the shopping list and immediately update the local state
    const handleAddItem = async (item, e) => {
        const icon = e.currentTarget.querySelector('i');
        try {
            // Access the <i> tag inside the button and change its color
            if (icon) {
                icon.style.color = 'gray';  // Disable color
                icon.className = 'fas fa-clock';
                icon.cursor = 'not-allowed';
            }

            await addItemToShoppingList(item.merchantName, item);
            await fetchShoppingList();
        } catch (error) {
            console.error('Error adding item to shopping list:', error);
        }
    };

    // Remove an item from the shopping list and immediately update the local state
    const handleRemoveItem = async (item, e) => {
        const icon = e.currentTarget.querySelector('i');
        try {
            // Access the <i> tag inside the button and change its color
            if (icon) {
                icon.style.color = 'gray';  // Disable color
                icon.className = 'fas fa-clock';
                icon.cursor = 'not-allowed';
            }

            await removeItemFromShoppingList(item.merchantName, item);
            await fetchShoppingList();
        } catch (error) {
            console.error('Error removing item from shopping list:', error);
        }
    };


    // Group items by category
    // Ensure items is an array and handle the case where it's undefined or null
    const groupedItems = (Array.isArray(items) ? items : []).reduce((groups, item) => {
        const category = item.itemCategory || 'Uncategorized';  // Default to 'Uncategorized' if no category
        if (!groups[category]) groups[category] = [];
        groups[category].push(item);
        return groups;
    }, {});

    if (loading) {
        return <div className="general-page"><h1>Loading...</h1></div>; // Display loading state while fetching data
    }

    return (
        <div className="general-page">
            <h1>
                <img src={`/api/flyers/proxy-image?url=${encodeURIComponent(merchant.logoUrl)}`} alt={`${merchant.name} logo`} />
                <br />
                {merchant.name}
            </h1>
            <div className='main-container'></div>

            <div className="items-container">
                {validity.validFrom && validity.validTo && (
                    <p>Valid from: {new Date(validity.validFrom).toLocaleDateString()} to {new Date(validity.validTo).toLocaleDateString()}</p>
                )}

                {/* Display a message if no items are found */}
                {items.length === 0 ? (
                    <p>No flyer items found for this merchant.</p>
                ) : (
                    <>
                        {/* Horizontal menu with category anchor links */}
                        <nav className="category-nav">
                            {Object.keys(groupedItems).map((category) => (
                                <a key={category} href={`#category-${category}`} className="category-link">
                                    {category}
                                </a>
                            ))}
                        </nav>

                        {/* Render items grouped by category */}
                        {Object.keys(groupedItems).map((category) => (
                            <div key={category} id={`category-${category}`} className="category-section">
                                <h2>{category}</h2>
                                <table className="items-table">
                                    <tbody>
                                        {groupedItems[category].map((item) => (
                                            <tr key={item._id}>
                                                <td>
                                                    <img
                                                        src={`/api/flyers/proxy-image?url=${encodeURIComponent(item.itemImageUrl)}`}
                                                        alt={item.itemName}
                                                        className="thumbnail"
                                                    />
                                                </td>
                                                <td>{item.itemName} <small>{item.bodyEn}</small><br /><small>{item.itemBrand}</small></td>
                                                <td>{renderPriceInfo(item)}</td>
                                                <td>
                                                    {item.isInShoppingList ? (
                                                        <button
                                                            onClick={(e) => handleRemoveItem(item, e)}
                                                            className="icon-button"
                                                        >
                                                            <i className="fas fa-circle-check" style={{ color: '#28a745', fontSize: '18px' }}></i>
                                                        </button>
                                                    ) : (
                                                        <button
                                                            onClick={(e) => handleAddItem(item, e)}
                                                            className="icon-button"
                                                        >
                                                            <i className="fas fa-circle-plus" style={{ color: '#007bff', fontSize: '18px' }}></i>
                                                        </button>
                                                    )}
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ))}
                    </>
                )}

                <div className="back-to-main">
                    <Link to="/browse-flyers" className="back-link">Back to Flyers</Link>
                </div>
            </div>
        </div>
    );
};

export default FlyerItems;
