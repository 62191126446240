import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import ModalClose from './modals/CloseShoppingList';
import ModalAdd from './modals/AddShoppingList';
import { UserContext } from './context/UserContext';
import { ShoppingContext } from './context/ShoppingContext';
import { Link } from 'react-router-dom';
import './css/General.css';

const ShoppingList = () => {
    const { user } = useContext(UserContext);
    const {
        shoppingList,
        removeItemFromShoppingList,
        fetchShoppingList,
        groupItemsByMerchant,
        formatShoppingListForEmail
    } = useContext(ShoppingContext);

    const [totalPrice, setTotalPrice] = useState(0);
    const [email, setEmail] = useState('');
    const [showModalClose, setShowModalClose] = useState(false);
    const [showModalAdd, setShowModalAdd] = useState(false);
    const [success, setSuccess] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [addedItems, setAddedItems] = useState(new Set());

    useEffect(() => {
        if (user) {
            setEmail(user.email);
        }
    }, [user]);

    // Use the shopping list from the ShoppingContext to update the added items and total price
    useEffect(() => {
        if (shoppingList && Array.isArray(shoppingList)) {

            // Calculate the total price
            const total = shoppingList.reduce((sum, item) => {
                if (!item || !item.itemPrice) {
                    console.warn('Skipping item due to missing properties:', item);
                    return sum;
                }

                let price = 0;
                if (typeof item.itemPrice === 'number') {
                    price = item.itemPrice;
                } else if (typeof item.itemPrice === 'string') {
                    price = parseFloat(item.itemPrice.replace(/[^0-9.]/g, '')) || 0;
                }
                return sum + price;
            }, 0);
            setTotalPrice(total);
        }
    }, [shoppingList]);


    // Handle live search functionality
    useEffect(() => {
        const performSearch = async () => {
            if (searchTerm && searchTerm.length >= 2) {
                try {
                    const response = await axios.get(`/api/search/items?query=${searchTerm}`, {
                        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                    });
                    setSearchResults(response.data.results);
                } catch (error) {
                    console.error('Error performing search:', error);
                }
            } else {
                setSearchResults([]);
            }
        };

        performSearch();
    }, [searchTerm]);


    // Handle removing an item from the shopping list
    const handleRemoveItem = async (merchantName, item) => {
        try {
            await removeItemFromShoppingList(merchantName, item); // Use the function from ShoppingContext

            // Update the addedItems set locally to reflect the removal immediately
            setAddedItems(prevSet => {
                const updatedSet = new Set(prevSet);
                updatedSet.delete(item.itemName);
                return updatedSet;
            });

            // Fetch the updated shopping list
            await fetchShoppingList();
        } catch (error) {
            console.error('Error removing item from shopping list:', error);
        }
    };

    // Handle closing the shopping list and emailing it to user
    const handleCloseList = async () => {
        try {
            // Close the shopping list and send email
            await axios.post('/api/shopping/close', {
                email,
                shoppingListHtml: formatShoppingListForEmail(shoppingList)
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setSuccess((
                <>
                    Shopping List email sent!<br />
                    Ready for new Shopping List.
                </>
            ));
            // Clear shopping list after closing
            setShowModalClose(false);
            await fetchShoppingList();
        } catch (error) {
            console.error('Error closing shopping list:', error);
        }
    };

    return (
        <div className="general-page">
            <h1>Shopping <s>Cart</s> List</h1>
            <div className='main-container'>
                <div className='preferences-container'>

                    {/* Show success message and disable form on success */}
                    {success && <div className="success-message">{success}</div>}

                    {shoppingList.length === 0 ? (
                        <div>No items in the shopping list.</div>
                    ) : (
                        <ul className="merchant-list">
                            {groupItemsByMerchant(shoppingList).map((merchantGroup, index) => (
                                <div key={index}>
                                    <h4>{merchantGroup.merchantName}</h4>
                                    <ul className="items-list">
                                        {merchantGroup.items.map((item, i) => (
                                            <li key={i} className="item-entry">
                                                <button
                                                    onClick={() => handleRemoveItem(merchantGroup.merchantName, item)}
                                                    className="icon-button"
                                                >
                                                    <i className="fas fa-circle-minus" style={{ color: 'darkred', fontSize: '18px' }}></i>
                                                </button>
                                                <span className="item-name">{item.itemName} - ${item.itemPrice}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </ul>

                    )}

                    {/* Add Item and Close Shopping List buttons */}
                    <div>
                        <br />
                        <button onClick={() => setShowModalAdd(true)} className="load-btn">
                            Add Items
                        </button>
                    </div>

                    <hr />
                    <h3>Total Price: ${totalPrice.toFixed(2)}</h3>
                    <button onClick={() => setShowModalClose(true)} className="save-btn" disabled={shoppingList.length === 0}>
                        eMail Shopping List
                    </button>

                    <br /><br />
                    <Link to="/shopping-archive">Review Archived Shopping Lists</Link>

                    {/* Modals */}
                    <ModalClose
                        show={showModalClose}
                        onClose={() => setShowModalClose(false)}
                        onSave={handleCloseList}
                        userEmail={email}
                    />
                    <ModalAdd
                        show={showModalAdd}
                        onClose={() => setShowModalAdd(false)}
                        search={searchTerm}
                        setSearch={setSearchTerm}
                        searchResults={searchResults}
                    />
                </div>
            </div>
        </div>
    );
};

export default ShoppingList;
