import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from './context/UserContext';
import MealPlanDisplay from './MealPlanDisplay';
import Modal from './modals/SaveNewMeal';
import './css/General.css';
import './css/Meal.css';

const MealPlanner = () => {
  const { user } = useContext(UserContext);  // Access user session
  const [isLoading, setIsLoading] = useState(false);
  const [gptLoading, setGptLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState('');
  const [mealPlans, setMealPlans] = useState([]);  // Stored meal plans
  const [generatedMeals, setGeneratedMeals] = useState(null);  // GPT-generated meal plan
  const [generationTime, setGenerationTime] = useState(null);  // To store the generation time
  const [isSaving, setIsSaving] = useState(false);  // Track saving status
  const [selectedAI, setSelectedAI] = useState('ChatGPT');  // AI selection dropdown
  const [showModal, setShowModal] = useState(false);  // Modal visibility
  const [planName, setPlanName] = useState('');  // Store the input meal plan name
  const [selectedMealPlan, setSelectedMealPlan] = useState(null);  // Selected meal plan to display

  // Helper functin to group ingredients by merchant 
  const groupIngredientsByMerchant = (recipes) => {
    const groupedIngredients = {};

    recipes.forEach(recipe => {
      recipe.ingredients.forEach(ingredient => {
        const { merchantName, itemName, itemPrice } = ingredient;

        if (!groupedIngredients[merchantName]) {
          groupedIngredients[merchantName] = [];
        }

        groupedIngredients[merchantName].push({ itemName, itemPrice });
      });
    });

    return groupedIngredients;
  };

  // Fetch meal plans on component mount
  useEffect(() => {
    const fetchMealPlans = async () => {
      setIsLoading(true);
      try {
        const res = await axios.get('/api/meal/plans', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        setMealPlans(res.data.mealPlans || []);
      } catch (error) {
        setErrorMessage('Failed to fetch meal plans.', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchMealPlans();
  }, [user]);



  // Generate new meal plan via GPT
  const generateMealPlan = async () => {
    setGptLoading(true);
    const startTime = new Date();  // Start timer
    try {
      const res = await axios.post('/api/meal/planner', { aiModel: selectedAI }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setGeneratedMeals(res.data.meals);
      setSelectedMealPlan(null);  // Clear the selected meal plan

      // Capture end time and calculate the duration
      const endTime = new Date();
      const duration = (endTime - startTime) / 1000;  // Convert to seconds
      setGenerationTime(duration);  // Store the duration
    } catch (error) {
      setErrorMessage(`Failed to generate meal plan: ${error.response ? error.response.data.message : error.message}`);
      console.error('Failed to generate meal plan:', error.response ? error.response.data : error);
    } finally {
      setGptLoading(false);
    }
  };

  // Save the generated meal plan
  const saveMealPlan = async () => {
    setIsSaving(true);
    try {
      console.log('Saving meal plan:', {
        name: planName,
        recipes: generatedMeals.recipes
      });

      const res = await axios.post('/api/meal/plans', {
        mealPlan: {
          name: planName,
          recipes: generatedMeals.recipes
        }
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });

      setMealPlans([...mealPlans, res.data.mealPlan]);  // Add the new meal plan to list
      setGeneratedMeals(null);
      setShowModal(false);
      setPlanName('');

      setSuccess((
        <>
          Meal Plan <b>{planName}</b> saved!
        </>
      ));
    } catch (error) {
      console.error('Failed to save meal plan:', error);
      setErrorMessage('Failed to save meal plan.', error);
    } finally {
      setIsSaving(false);
    }
  };

  // After saving the meal plan, ensure the shopping list is saved and displayed for the selected meal plan.
  const handleMealPlanClick = async (mealPlan) => {
    try {
      const res = await axios.get(`/api/meal/plans/${mealPlan._id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });

      setSelectedMealPlan(res.data.mealPlan);  // Set the selected meal plan to display its details
      setGeneratedMeals(null);  // Clear the generated meal plan if exists
    } catch (error) {
      setErrorMessage('Failed to retrieve meal plan details.');
      console.error('Error retrieving meal plan details:', error);
    }
  };

  return (
    <div className="general-page">
      <h1>Meal Planner</h1>
      <div className='main-container'>
        <div className='preferences-container'>

          {/* Show success message and disable form on success */}
          {success && <div className="success-message">{success}</div>}

          {/* Existing Meal Plans List */}
          {isLoading ? (
            <p>Loading meal plans...</p>
          ) : (
            mealPlans.length === 0 ? (
              <p>No saved meal plans. Generate a new one!</p>
            ) : (
              <div>
                <p>Saved Meal Plans:</p>
                <ul className='items-list'>
                  {mealPlans.map((plan) => (
                    <li className='item-entry' key={plan._id}>
                      <button onClick={() => handleMealPlanClick(plan)} className="load-btn">
                        Load
                      </button>
                      {plan.name} - {new Date(plan.date).toLocaleDateString()}
                    </li>
                  ))}
                </ul>
              </div>
            )
          )}

          {/* Display error messages */}
          {errorMessage && <div className="error-message"><br /><br /><hr />{errorMessage}</div>}

          {/* Display Selected Meal Plan */}
          {selectedMealPlan && (
            <MealPlanDisplay mealPlan={selectedMealPlan} groceryList={groupIngredientsByMerchant(selectedMealPlan.recipes)} />
          )}

          {/* Display GPT-generated meal plan */}
          {!selectedMealPlan && generatedMeals && (
            <MealPlanDisplay mealPlan={generatedMeals} groceryList={groupIngredientsByMerchant(generatedMeals.recipes)} />
          )}

          {/* Save the generated meal plan - Show Modal */}
          {!selectedMealPlan && generatedMeals && (
            <button onClick={() => setShowModal(true)} disabled={isSaving} className="save-btn">
              {isSaving ? 'Saving...' : 'Save Meal Plan'}
            </button>
          )}

          {/* Button to generate a new meal plan */}
          <br /><br /><hr />
          <button onClick={generateMealPlan} disabled={gptLoading} className="save-btn">
            {gptLoading ? (
              <>
                {selectedAI} Generating... <i className="fas fa-hourglass-half fa-spin"></i>
              </>
            ) : (
              'Generate New Meal Plan'
            )}
          </button>

          {/* Dropdown for selecting AI model */}
          <div className="ai-selection">
            <label htmlFor="aiSelect">Choose AI Model:</label>
            <select className="ai-selection" id="aiSelect" value={selectedAI} onChange={(e) => setSelectedAI(e.target.value)}>
              <option value="ChatGPT">ChatGPT</option>
              <option value="ClaudeAI">ClaudeAI</option>
            </select>
          </div>

          {/* Display the time it took to generate the meal plan */}
          {generationTime && (
            <div className="generation-time">
              <p>Meal plan generated in {generationTime} seconds.</p>
            </div>
          )}
        </div>
      </div>

      {/* Modal for entering plan name */}
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        onSave={saveMealPlan}
        planName={planName}
        setPlanName={setPlanName}
      />

    </div>
  );
};

export default MealPlanner;
