import React, { useState, useEffect, useContext } from 'react';
import { ShoppingContext } from './context/ShoppingContext';
import axios from 'axios';
import './css/General.css';

const ShoppingArchive = () => {
    const { groupItemsByMerchant } = useContext(ShoppingContext);
    const [archivedLists, setArchivedLists] = useState([]); // List of archived shopping lists
    const [selectedList, setSelectedList] = useState(null); // The selected shopping list to display
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        const fetchClosedShoppingLists = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get('/api/shopping/closed', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });
                setArchivedLists(response.data.shoppingLists || []);
            } catch (error) {
                setErrorMessage('Failed to fetch closed shopping lists.');
                console.error('Error fetching closed shopping lists:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchClosedShoppingLists();
    }, []);

    // Handle click on a shopping list to load its details
    const handleListClick = async (shoppingListId) => {
        try {
            const response = await axios.get(`/api/shopping/get/${shoppingListId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setSelectedList(response.data.shoppingList); // Set the selected shopping list for display
        } catch (error) {
            setErrorMessage('Failed to retrieve shopping list details.');
            console.error('Error retrieving shopping list details:', error);
        }
    };


    return (
        <div className="general-page">
            <h1>Shopping Archive</h1>
            <div className='main-container'>
                <div className='preferences-container'>

                    {/* Display error messages */}
                    {errorMessage && <div className="error-message"><br /><br /><hr />{errorMessage}</div>}

                    {/* Display archived shopping lists */}
                    {isLoading ? (
                        <p>Loading archived shopping lists...</p>
                    ) : (
                        archivedLists.length === 0 ? (
                            <p>No archived shopping lists found.</p>
                        ) : (
                            <div>
                                <p>Archived Shopping Lists:</p>
                                <ul className="item-list">
                                    {archivedLists.map((list) => (
                                        <li className='item-entry' key={list._id}>
                                            <button onClick={() => handleListClick(list._id)} className="load-btn">
                                                Load
                                            </button>
                                            Shopping List - {new Date(list.date).toLocaleDateString()}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )
                    )}

                    {/* Display the selected shopping list's items grouped by merchant */}
                    {selectedList && (
                        <div className='shopping-list-display'>
                            <h2>Shopping List from {new Date(selectedList.date).toLocaleDateString()}</h2>
                            <ul className="merchant-list">
                                {groupItemsByMerchant(selectedList.items).map((merchantGroup, index) => (
                                    <div key={index}>
                                        <h4>{merchantGroup.merchantName}</h4>
                                        <ul className="items-list">
                                            {merchantGroup.items.map((item, i) => (
                                                <li key={i} className="item-entry">
                                                    <span className="item-name">{item.itemName} - ${item.itemPrice}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ShoppingArchive;
